.gm-ui-hover-effect {
  display: none;
}

/* Base styling for the InfoWindow */
.info-window {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 0.9rem;
  max-width: 250px;
  transition: all 0.3s ease-in-out;
}

/* Default state (before hover) */
.info-window:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  padding: 10px;
}

/* Additional details initially hidden */
.info-window .additional-details {
  display: none;
  border-top: 1px solid #ddd;
  color: #555;
  font-size: 12px;
  padding-top: 5px;
}

/* Show additional details on hover */
.info-window:hover .additional-details {
  display: flex;
  flex-direction: column;
}
